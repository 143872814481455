import { AccordionContentProps, Content } from "@radix-ui/react-accordion";
import React from "react";

/**
 * Wrapper around Radix-UI Accordion.Content.
 * TODO: Generalize this component to `Accordion/Content`
 */
const NavAccordionContent = React.forwardRef<
  HTMLDivElement,
  AccordionContentProps
>(({ children, className: _className, ...props }, forwardedRef) => (
  <Content
    className="data-[state=open]:animate-radix-accordion-slide-down overflow-hidden data-[state=closed]:animate-radix-accordion-slide-up"
    ref={forwardedRef}
    {...props}
  >
    {children}
  </Content>
));

NavAccordionContent.displayName = "NavAccordionContent";

export default NavAccordionContent;
