import Link from "next/link";
import React from "react";

export default function NavAccordionLink({
  href,
  children,
  ...props
}: React.HTMLProps<HTMLAnchorElement>) {
  return (
    <Link href={href ?? ""} passHref>
      <a
        className="hover:bg-hmio-black-800 block px-4 text-base font-semibold py-3 cursor-pointer"
        {...props}
      >
        {children}
      </a>
    </Link>
  );
}
