export const truncateString = (str: string, chars: number): string => {
  if (str.length <= chars) {
    return str;
  }

  return `${str.slice(0, chars)}...`;
};

export const truncateBetweenString = (str: string, chars: number): string => {
  if (str.length <= chars) {
    return str;
  }

  const first = str.slice(0, chars);
  const last = str.slice(str.length - chars, str.length);

  return `${first}...${last}`;
};
