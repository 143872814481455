import { useState } from "react";
import Image, { ImageProps } from "next/image";
import {
  LazyLoadImage,
  LazyLoadImageProps,
} from "react-lazy-load-image-component";

type ImageBypassProp = {
  bypassNextImage?: boolean;
  fillerClass?: string;
};

/**
 * A wrapper component around next/image
 *
 * @param bypassNextImage bypass next/image and load the image using react-lazy-load-image-component
 */
export const ImageOrNull = ({
  bypassNextImage,
  fillerClass = "bg-hmio-black-700",
  onError,
  ...props
}: (ImageProps | LazyLoadImageProps) & ImageBypassProp) => {
  const [loadError, setLoadError] = useState(false);

  const component = bypassNextImage ? (
    <LazyLoadImage
      {...(props as LazyLoadImageProps)}
      onError={(e) => {
        onError?.(e);
        setLoadError(true);
      }}
    />
  ) : (
    // eslint-disable-next-line jsx-a11y/alt-text
    <Image
      {...(props as ImageProps)}
      onError={(e) => {
        onError?.(e);
        setLoadError(true);
      }}
    />
  );

  return !loadError && props.src !== "" ? (
    component
  ) : (
    <figure
      style={{
        height: `${props.height}px`,
        width: `${props.width}px`,
      }}
    >
      <div className={`w-full h-full ${fillerClass} rounded-full`}></div>
    </figure>
  );
};
