import { ImageOrNull } from "components/Image/ImageOrNull";
import { formatSolString } from "src/utils";
import { formatNum } from "src/utils/format/formatNum";
import { CDN_URL, formatCdnUrl, ImageSize } from "src/utils/getCdnLink";
import { truncateString } from "src/utils/truncateString";

interface TokenMetaProps {
  slug: string;
  name?: string;
  symbol?: string;
  volume?: number;
}

const TokenMetaCard = ({ name, slug, symbol, volume }: TokenMetaProps) => {
  return (
    <div className="flex items-center text-sm font-semibold cursor-pointer font-Inter">
      <ImageOrNull
        src={formatCdnUrl(CDN_URL.DEFI, ImageSize.TABLE, slug)}
        alt={"Token Icon Image"}
        height={40}
        width={40}
        style={{
          borderRadius: "30px",
          objectFit: "cover",
          cursor: "pointer",
        }}
        fillerClass={
          "rounded-full bg-hmio-black-700 group-hover:bg-hmio-black-600"
        }
      />
      <div className="flex flex-col ml-4 text-base not-italic font-medium font-Inter ">
        {name ? <div className="">{truncateString(name, 22)}</div> : null}
        {volume ? (
          <span className="text-xs text-hmio-black-400 font-Inter">
            {`1D Volume: ${formatSolString(formatNum(volume, 2, true))}`}
          </span>
        ) : null}
      </div>
      {symbol ? (
        <div className="ml-4 text-hmio-black-400 ">{symbol}</div>
      ) : null}
    </div>
  );
};

export default TokenMetaCard;
