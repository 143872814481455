export const getKeyUpTrigger =
  (
    targetKey: string,
    callbackIfTrue: Function,
    callbackIfFalse?: Function,
  ): React.KeyboardEventHandler =>
  (e) => {
    if (e.key === targetKey) {
      callbackIfTrue();
    } else {
      if (callbackIfFalse) callbackIfFalse();
    }
  };
