import styled from "styled-components";
import SearchCloseIcon from "../../src/icons/searchClose.svg";
import { useEffect, useRef, useState } from "react";
import GlobalSearchInput from "./GlobalSearchInput";
import { EntityType, getRoute } from "../../src/routes/routing";
import {
  ListNftRequestParams,
  ListNftResponse,
  NftSummary,
} from "../../model/aggregations/nfts";
import {
  ListTokensRequest,
  ListTokensResponse,
  TokenListItems,
} from "../../model/aggregations/defiTokens";
import { LeaderboardTimeRanges, Sorts } from "../../model/aggregations";
import { useDebounce } from "../../src/hooks/useDebounce";
import { getKeyUpTrigger } from "../../src/utils/keyHandlers";
import useClient from "../../src/hooks/useClient";
import useMounted from "src/hooks/useMounted";
import TokenMetaCard from "components/Card/Meta/TokenMetaCard";
import Anchor from "components/Anchor/Anchor";
import If from "components/Conditionals/If";
import NftCollectionMetaCard from "components/Card/Meta/NftCollectionMeta";
import useOnClickOutside from "src/hooks/useOnClickOutside";
import {
  DefiProgramSearchItem,
  GetDefiProgramsForSearchResponse,
} from "model/aggregations/defiPrograms";

interface GlobalSearchProps {
  screenType: string;
}

// only used to control absolute positioning of global search dropdown
// TODO: create cleaner logic to handle search and dropdown positioning
interface UiOpts {
  width?: string;
  top?: string;
  right?: string;
  left?: string;
}

export type GlobalSearchResult = {
  name: string;
  type: EntityType;
  route: string;
};

const RESULTS_TO_DISPLAY = 10 as const;

interface GlobalSearchBaseUIProps {
  name: string; // name of the entity
  slug: string; // slug of the entity
}

export interface NftSearchUIProps extends NftSummary {}

export interface TokenSearchUIProps extends GlobalSearchBaseUIProps {
  symbol?: string; // symbol of the token
  id: string;
  volume: number; // volume of the entity
}
const getSearchResultFromToken = (
  token: TokenListItems,
): TokenSearchUIProps => ({
  name: `${token.name}`,
  slug: token.slug,
  symbol: token.symbol,
  id: token.id,
  volume: token.volumeSwapUsd,
});

interface ProgramSearchUIProps extends GlobalSearchBaseUIProps {}
const getSearchResultFromProgram = (
  program: DefiProgramSearchItem,
): ProgramSearchUIProps => ({
  name: program.name,
  slug: program.slug,
});

interface GlobalSearchUIProps {
  nfts: NftSearchUIProps[];
  tokens: TokenSearchUIProps[];
  programs: ProgramSearchUIProps[];
}

interface GlobalDropDownProps {
  uiOpts?: UiOpts;
  debouncedSearchQuery: string;
  hasNft: boolean;
  hasToken: boolean;
  hasProgram: boolean;
  handleEnter: () => void;
  results: GlobalSearchUIProps;
}

const GlobalDropDown = ({
  debouncedSearchQuery,
  handleEnter,
  hasNft,
  hasProgram,
  hasToken,
  results,
  uiOpts,
}: GlobalDropDownProps) => {
  return (
    <If condition={debouncedSearchQuery.length > 0}>
      <div
        className={`${uiOpts?.width} ${uiOpts?.top} ${uiOpts?.right} ${uiOpts?.left} rounded-b-lg absolute text-hmio-white bg-hmio-black-900 z-[10000]`}
      >
        <If condition={hasNft}>
          {results.nfts.map((nft) => (
            <Anchor to={getRoute(nft.slug, EntityType.NFT)} key={nft.name}>
              <div
                className="py-3 pl-4 hover:bg-hmio-black-800"
                onClick={() => handleEnter()}
              >
                <NftCollectionMetaCard NftCollectionMeta={nft} />
              </div>
            </Anchor>
          ))}
        </If>
        <If condition={hasToken}>
          {results.tokens.map((token) => (
            <Anchor
              to={getRoute(token.slug, EntityType.DEFI_TOKEN)}
              key={token.name}
            >
              <div
                className="py-3 pl-4 hover:bg-hmio-black-800"
                onClick={() => handleEnter()}
              >
                <TokenMetaCard
                  volume={token.volume}
                  slug={token.slug}
                  name={token.symbol ?? ""}
                />
              </div>
            </Anchor>
          ))}
        </If>
        <If condition={hasProgram}>
          {results.programs.map((program) => (
            <Anchor
              to={getRoute(program.slug, EntityType.DEFI_PROGRAM)}
              key={program.name}
            >
              <div
                className="py-3 pl-4 hover:bg-base-blue-700"
                onClick={() => handleEnter()}
              >
                <TokenMetaCard slug={program.slug} name={program.name ?? ""} />
              </div>
            </Anchor>
          ))}
        </If>
      </div>
    </If>
  );
};

const GlobalSearch = ({ screenType }: GlobalSearchProps) => {
  const [searchFocused, setSearchFocused] = useState<boolean>(true);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [isQueryClear, setIsQueryClear] = useState<boolean>(
    searchQuery.length === 0,
  );
  const [results, setResults] = useState<GlobalSearchUIProps>({
    nfts: [],
    tokens: [],
    programs: [],
  });
  const [selected, setSelected] = useState<number>(0);
  const nftClient = useClient("nft");
  const defiTokens = useClient("defiTokens");
  const programClient = useClient("defiPrograms");
  // Use this value for any case where we need to wait until they stopped typing.
  const debouncedSearchQuery = useDebounce(searchQuery.toLowerCase(), 200);
  let { isMounted, mountRef } = useMounted();

  useEffect(() => {
    async function search() {
      const nftPromise = new Promise((resolve) => {
        const nft = nftClient.listNfts({
          params: {
            pageSize: 4,
            nameFilter: debouncedSearchQuery.trim(),
            sort: Sorts.VOLUME,
            timeRangeFilter: LeaderboardTimeRanges.DAY,
          } as ListNftRequestParams,
        });

        resolve(nft);
      });

      const tokenPromise = new Promise((resolve) => {
        const tokenRes = defiTokens.listTokensV3({
          params: {
            pageSize: 4,
            nameFilter: debouncedSearchQuery.trim(),
            sort: Sorts.VOLUME,
            timeRangeFilter: LeaderboardTimeRanges.DAY,
          } as ListTokensRequest,
        });

        resolve(tokenRes);
      });

      const programPromise = new Promise((resolve) => {
        const tokenRes = programClient.getDefiProgramsForSearch({
          params: {
            pageSize: 3,
            nameFilter: debouncedSearchQuery.trim(),
            sort: Sorts.VOLUME,
            timeRangeFilter: LeaderboardTimeRanges.DAY,
          } as ListTokensRequest,
        });

        resolve(tokenRes);
      });

      let globalResults: GlobalSearchUIProps = {
        nfts: [],
        tokens: [],
        programs: [],
      };
      const promises = [nftPromise, tokenPromise, programPromise];

      await Promise.allSettled(promises).then((results) => {
        results.map((result) => {
          const status = result.status;
          if (status === "fulfilled") {
            const value = result.value as
              | ListNftResponse
              | ListTokensResponse
              | GetDefiProgramsForSearchResponse;

            if ("nfts" in value) {
              globalResults.nfts = value.nfts;
            } else if ("tokens" in value) {
              globalResults.tokens = value.tokens.map(getSearchResultFromToken);
            } else if ("programs" in value) {
              globalResults.programs = value.programs.map(
                getSearchResultFromProgram,
              );

              if ("solana".includes(debouncedSearchQuery.toLowerCase())) {
                globalResults.programs.push({
                  name: "Solana",
                  slug: "solana",
                });
              }
            }
          }
        });
      });
      setResults(() => globalResults);
    }

    if (isMounted && debouncedSearchQuery.length > 0) {
      search();
    } else {
      mountRef.current = true;
    }
    // TODO: refactor this
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchQuery]);

  const closeSearch = () => {
    setSearchQuery("");
    setSearchFocused(true);
    setResults({
      nfts: [],
      tokens: [],
      programs: [],
    });
    setIsQueryClear(searchQuery.length === 0);
  };

  const handleInputChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    e.preventDefault();

    setSearchQuery(e.target.value);
  };

  const handleArrow = (up: boolean) => {
    if (up) {
      if (selected === 0) {
        return;
      }

      setSelected((selected) => selected - 1);
    } else {
      if (selected === RESULTS_TO_DISPLAY - 1) {
        return;
      }

      setSelected((selected) => selected + 1);
    }
  };

  const handleEnter = () => {
    if (!results.nfts && !results.tokens) {
      return;
    }

    closeSearch();
  };

  const handleKeyUp = getKeyUpTrigger("Enter", handleEnter);
  const ref = useRef<HTMLDivElement | null>(null);

  const handleClickOutside = () => {
    if (debouncedSearchQuery.length > 0) {
      closeSearch();
    }
  };

  useOnClickOutside(ref, handleClickOutside);

  const hasNft = results.nfts.length > 0;
  const hasToken = results.tokens.length > 0;
  const hasProgram = results.programs.length > 0;

  return (
    <div
      className="flex flex-row items-center w-full"
      onKeyUp={handleKeyUp}
      ref={ref}
    >
      {screenType === "desktop" ? (
        <GlobalSearchInput
          onCancel={closeSearch}
          onChange={handleInputChange}
          onArrowPress={handleArrow}
          searchQuery={searchQuery}
          searchFocused={searchFocused}
          isQueryClear={isQueryClear}
        >
          <GlobalDropDown
            debouncedSearchQuery={debouncedSearchQuery}
            handleEnter={handleEnter}
            hasNft={hasNft}
            hasProgram={hasProgram}
            hasToken={hasToken}
            results={results}
            uiOpts={{
              width: "w-80",
              top: "top-[52px]",
              left: "left-[15px]",
            }}
          />
        </GlobalSearchInput>
      ) : null}
      {screenType === "mobile" ? (
        <div className="flex flex-1">
          <GlobalSearchInput
            mobile
            onCancel={closeSearch}
            onChange={handleInputChange}
            onArrowPress={handleArrow}
            searchQuery={searchQuery}
            searchFocused={searchFocused}
            isQueryClear={isQueryClear}
          >
            <GlobalDropDown
              debouncedSearchQuery={debouncedSearchQuery}
              handleEnter={handleEnter}
              hasNft={hasNft}
              hasProgram={hasProgram}
              hasToken={hasToken}
              results={results}
              uiOpts={{
                width: "w-screen",
                top: "top-[54px]",
                left: "-left-[64px]",
              }}
            />
          </GlobalSearchInput>
          <div
            role="button"
            className="flex align-center"
            onClick={() => {
              closeSearch();
              setSearchFocused(true);
            }}
          >
            <SearchCloseIcon height="18" width="18" viewBox="0 0 30 30" />
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default GlobalSearch;
