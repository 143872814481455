export enum DefiHomeSlugRoutes {
  tokens = "tokens",
  programs = "programs",
}

export enum DefiTokenSlugRoutes {
  overview = "overview",
  owners = "owners",
  stats = "stats",
}
