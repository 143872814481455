import { AuthProvider } from "components/AuthProvider/AuthProvider";
import FooterV2 from "components/Footer/SimpleFooterV2";
import Nav from "components/Nav/Nav";
import WalletAdapterContext from "components/Wallet/WalletAdapterContext/WalletAdapterContext";
import { WSSubscriptionProvider } from "components/WSSubscriptionProvider/WSSubscriptionProvider";
import { DynamoConfigContextProvider } from "src/context/configContext";

interface LayoutProps {
  children: any;
  footer?: boolean;
  tempSpacer?: boolean;
  dataBar?: boolean;
}

/**
 *
 * Solana Wallet Adapter Context Wraps Our Layout
 *
 * Nav
 * Main
 * Footer
 *
 * @returns
 */
const Layout = ({
  children,
  footer = true,
  tempSpacer = true,
  dataBar = true,
}: LayoutProps) => {
  return (
    <>
      <DynamoConfigContextProvider>
        <WSSubscriptionProvider>
          <WalletAdapterContext>
            <AuthProvider>
              <Nav />
              <main>{children}</main>
              {/** TODO: Temp spacer to avoid weird borders */}
              {tempSpacer ? <div className="h-4" /> : null}
              {footer ? <FooterV2 /> : null}
            </AuthProvider>
          </WalletAdapterContext>
        </WSSubscriptionProvider>
      </DynamoConfigContextProvider>
    </>
  );
};

export default Layout;
