import Input from "components/Input/Input";
import { PropsWithChildren, useEffect } from "react";
import { useRef } from "react";
import { getKeyUpTrigger } from "../../src/utils/keyHandlers";

type GlobalSearchInputProps = {
  onCancel: () => void;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  onArrowPress: (upElseDown: boolean) => void;
  searchQuery: string;
  searchFocused: boolean;
  isQueryClear: boolean;
  mobile?: boolean;
};

const GlobalSearchInput = ({
  mobile = false,
  onArrowPress,
  onCancel,
  onChange,
  searchQuery,
  searchFocused,
  isQueryClear,
  children,
}: PropsWithChildren<GlobalSearchInputProps>) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const handleEscape = getKeyUpTrigger("Escape", onCancel);
  const handleUp = getKeyUpTrigger("ArrowUp", () => onArrowPress(true));
  const handleDown = getKeyUpTrigger("ArrowDown", () => onArrowPress(false));
  const handleKeyUp: React.KeyboardEventHandler = (e) => {
    handleEscape(e);
    handleUp(e);
    handleDown(e);
  };

  useEffect(() => {
    searchFocused || isQueryClear
      ? inputRef.current?.focus()
      : inputRef.current?.blur();
  }, [searchFocused, isQueryClear]);

  return (
    <div className="relative flex-1 lg:w-full" onKeyUp={handleKeyUp}>
      <Input
        className={
          mobile ? "rounded-none shadow-none bg-hmio-black" : undefined
        }
        style={!mobile ? "search" : undefined}
        ref={inputRef}
        onChange={onChange}
        value={searchQuery}
        placeholder="Search a collection, token or protocol"
      />
      {children}
    </div>
  );
};

export default GlobalSearchInput;
