import { TransactionActivitySlug } from "components/MarketPlace/types";
import { DefiToken } from "model/aggregations/defiTokens";
import { AppNestedRoutes, AppRoutes } from "./appRoutes";
import {
  DefiHomeSlugRoutes,
  DefiTokenSlugRoutes,
} from "./componentRoutes/defiTabRoutes";

export enum EntityType {
  NFT,
  DEFI_PROGRAM,
  DEFI_TOKEN,
}

export enum Routes {
  HOME = "/nfts/top-projects",
}

export const createNftRoute = (slug: string) =>
  `${AppRoutes.nft}/${slug}/overview`;

export const createDefiProgramRoute = (slug: string) =>
  `${AppRoutes.defi}/${AppNestedRoutes.programs}/${slug}`;

/**
 * Creates a route to `solana-defi/[tab]`
 * @param tab optional `DefiHomeSlugRoutes`, defaults to `DefiProgramSlugRoutes.tokens`
 */
export const createDefiHomeRoute = (tab = DefiHomeSlugRoutes.tokens) => {
  return `${AppRoutes.defi}/${tab}`;
};

/**
 * Creates a route to `solana-defi/tokens/[slug]/[tab]`
 *
 * @param slug slug of the token
 * @param tab optional `DefiTokenSlugRoutes`, defaults to `DefiTokenSlugRoutes.overview`
 */
export const createDefiTokenRoute = (
  slug: string,
  tab = DefiTokenSlugRoutes.overview,
) => {
  if (slug === "11111111111111111111111111111111") {
    return AppRoutes.solana;
  }
  return `${AppRoutes.defi}/${AppNestedRoutes.tokens}/${slug}/${tab}`;
};

export const getRoute = (slug: string, type: EntityType) => {
  if (slug === "solana") {
    return AppRoutes.solana;
  }

  switch (type) {
    case EntityType.NFT:
      return createNftRoute(slug);
    case EntityType.DEFI_PROGRAM:
      return createDefiProgramRoute(slug);
    case EntityType.DEFI_TOKEN:
      return createDefiTokenRoute(slug);
    default:
      return "";
  }
};

export const createTradeRoute = (asset: DefiToken): string => {
  return `${AppRoutes.swap}/${AppNestedRoutes.token}/${asset.slug}`;
};

export const getDefaultTradeRoute = (): string => {
  return `${AppRoutes.swap}/${AppNestedRoutes.token}/dust-protocol`;
};

export const createMarketPlaceActivityRoute = (
  nftSlug: string,
  activity: TransactionActivitySlug,
): string => {
  return `${AppRoutes.nft}/${nftSlug}/marketplace/${activity}`;
};

export const createNftDetailsRoute = (
  mint: string,
  activity: TransactionActivitySlug,
): string => {
  return `${AppRoutes.nft}/${mint}/details/${activity}`;
};

export const doesMatchPathName = (
  locationPathName: string,
  allowedPathNames: string[],
): boolean => {
  return allowedPathNames.includes(locationPathName);
};
