import {
  Header,
  Trigger,
  AccordionTriggerProps,
} from "@radix-ui/react-accordion";
import ChevronUp from "src/icons/V2/Utility/Chevron_Up.svg";
import ChevronDown from "src/icons/V2/Utility/Chevron_Down.svg";
import React, { useRef } from "react";

const NavAccordionHeader = React.forwardRef<
  HTMLButtonElement,
  AccordionTriggerProps
>(({ children, className, ...props }: AccordionTriggerProps, forwardedRef) => {
  return (
    <Header>
      <Trigger
        className={`${className} flex justify-between w-full [&>*]:data-[state=open]:rotate-180`}
        {...props}
        ref={forwardedRef}
      >
        {children}
        <ChevronDown
          height="32"
          width="32"
          viewBox="0 0 24 24"
          className="fill-white duration-300 ease-in-out"
        />
      </Trigger>
    </Header>
  );
});

NavAccordionHeader.displayName = "NavAccordionHeader";
export default NavAccordionHeader;
