import { ImageOrNull } from "components/Image/ImageOrNull";
import { NftSummary } from "model/aggregations/nfts";
import { formatSolString } from "src/utils";
import { formatNum } from "src/utils/format/formatNum";
import { CDN_URL, formatCdnUrl, ImageSize } from "src/utils/getCdnLink";
import styled from "styled-components";

const NftCollectionMetaCardContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  .small-icon {
    display: flex;
    align-items: center;
    border-radius: 30px;
    max-width: 40px;
    min-width: 40px;
    height: 40px;
  }
`;

interface NftCollectionMetaCardProps {
  NftCollectionMeta: NftSummary;
}

const NftCollectionMetaCard = ({
  NftCollectionMeta,
}: NftCollectionMetaCardProps) => {
  const { slug, name: collectionName, volume } = NftCollectionMeta;

  return (
    <NftCollectionMetaCardContainer>
      <ImageOrNull
        src={formatCdnUrl(CDN_URL.NFTS, ImageSize.HOVER, slug)}
        height={40}
        width={40}
        style={{
          borderRadius: "30px",
          objectFit: "cover",
          cursor: "pointer",
        }}
        fillerClass={
          "rounded-full bg-hmio-black-700 group-hover:bg-hmio-black-600"
        }
      />
      <div className="ml-4 text-base not-italic font-medium font-Inter">
        <div className="text-hmio-white">{collectionName}</div>
        {volume ? (
          <span className="text-xs text-hmio-black-400 font-Inter">
            {`1D Volume: ${formatSolString(formatNum(volume, 2, true))}`}
          </span>
        ) : null}
      </div>
    </NftCollectionMetaCardContainer>
  );
};

export default NftCollectionMetaCard;
