// This is our Logo.
export const DEFAULT_PLACEHOLDER_IMAGE =
  "https://images.hellomoon.io/nft_placeholder_400.png";
// This is a grey anonymous silouhette
export const TWITTER_PLACEHOLDER_IMAGE =
  "https://images.hellomoon.io/twitter_placeholder.png";

export enum CDN_URL {
  BASE = "https://images.hellomoon.io",
  NFTS = "https://images.hellomoon.io/nfts",
  DEFI = "https://images.hellomoon.io/defi",
  PROGRAM = "https://images.hellomoon.io/program",
}

export enum ImageSize {
  LARGE = 400,
  HOVER = 200,
  DEFI_HEADLINE = 24,
  HEADLINE = 60,
  HEADLINE_MOBILE = 172,
  TABLE = 30,
  TABLE_MOBILE = 40,
  MARKETPLACE = 436,
}

// Add whitelisted domains here AND in next.config.js
const IMAGE_URL_WHITELIST = [
  "images.hellomoon.io",
  "pbs.twimg.com",
  "github.com",
];

/**
 * @deprecated Please use formatCdnUrl and the CDN_URL enum instead.
 * Retrieve the CDN link for images by its resource type, size, and slug.
 *
 * Sometimes a slug is undefined, so no image can be obtained,
 * so instead we send a placeholder image.
 *
 * @param slug the slug, or undefined
 * @param size the size of the image to retrieve, either 400 or 32
 * @returns the URL string to the image if it exists, otherwise the URL to the placeholder image.
 */
export function getCdnLink(
  resource: "defi" | "nfts",
  size: ImageSize,
  slug: string | undefined,
): string {
  return slug
    ? `${CDN_URL.BASE}/${resource}/${size}/${slug}.webp`
    : DEFAULT_PLACEHOLDER_IMAGE;
}

// Convenience function for formatting urls
export function formatCdnUrl(
  baseUrl: CDN_URL,
  size: ImageSize,
  slug: string | undefined,
): string {
  return slug ? `${baseUrl}/${size}/${slug}.webp` : "";
}
