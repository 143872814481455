import { formatNum, FormatNumNullReturnValue } from "./formatNum";

export const formatDollars = (amount: number | null | undefined): string =>
  !amount ? "Unknown" : `$${amount.toFixed(2)}`;

export const formatDollarsString = (amount: string): string => `$${amount}`;

export const formatSolString = (amount: string | number): string =>
  `${amount} ◎`;

export const formatSolStringFromFront = (amount: string): string =>
  `◎ ${amount}`;

export const standardFormatDollars = (n: number): string =>
  formatDollarsString(formatNum(n, 2, false));

export const formatSolanaWithFrontSign = (
  n: number | undefined,
  d: number = 0,
  flatZero = true,
) => {
  if (n === undefined) {
    return FormatNumNullReturnValue.TBU;
  }

  if (n >= 0) {
    return `+◎${formatNum(n, 2, true)}`;
  } else {
    return `-◎${formatNum(Math.abs(n), 2, true)}`;
  }
};

export const formatSolanaWithBackSign = (
  n: number | undefined,
  d: number = 0,
  flatZero = true,
) => {
  if (n === undefined) {
    return FormatNumNullReturnValue.TBU;
  }

  if (n >= 0) {
    return `+${formatNum(n, 2, true)}◎`;
  } else {
    return `${formatNum(n, 2, true)}◎`;
  }
};
