import React, { useState } from "react";
import Search from "src/icons/V2/Utility/Search.svg";

interface InputProps {
  style?: "default" | "search";
  mobile?: boolean;
}

export default function Input({
  type,
  style = "default",
  placeholder,
  onChange,
  onKeyDown,
  value,
  mobile = false,
  ...props
}: InputProps & React.HTMLProps<HTMLInputElement>) {
  const [focused, setFocused] = useState<boolean>(false);
  const defaultClass = `
    rounded-full
    text-sm
    text-hmio-black-400
    ${style === "search" ? "px-5 py-2" : "px-5 py-2.5"}
    gap-2.5
    flex
    duration-200
    ease-in-out
    ${mobile ? `hover:shadow-inner-border-1 hover:shadow-hmio-white` : null}
    ${focused ? "shadow-inner-border-1 " : ""}
    w-[340px]
    `;
  const defaultBg = "bg-hmio-black-900";

  return (
    <div
      className={[
        defaultClass,
        props.className,
        props.className?.includes("bg-") ? "" : defaultBg,
      ].join(" ")}
    >
      {style === "search" ? (
        <Search
          className={[
            focused ? "fill-hmio-white" : "fill-hmio-black-600",
            "duration-200 ease-in-out",
          ].join(" ")}
        />
      ) : null}
      <input
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        className="flex-grow focus:outline-none text-hmio-white"
        placeholder={placeholder}
        value={value}
        type={type}
        onChange={onChange}
        onKeyDown={onKeyDown}
      />
    </div>
  );
}
