import { Trigger } from "@radix-ui/react-navigation-menu";

interface NavTriggerProps {
  children: React.ReactNode;
  active?: boolean;
}

/**
 * Wrapper around Radix-UI NavigationMenu.Trigger. This should only
 * be used within a Radix navigation menu.
 */
export default function NavLabel({
  children,
  active = false,
  ...props
}: NavTriggerProps) {
  const defaultClass =
    "inline-block font-medium text-sm leading-4 hover:text-hmio-blue group-hover:text-hmio-blue cursor-pointer duration-75 h-full inline-flex align-start";
  return (
    <Trigger className={`${defaultClass} ${active ? "text-hmio-blue" : ""}`}>
      {children}
    </Trigger>
  );
}
